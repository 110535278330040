<template>
  <div class='pb45'>
    <!-- 轮播图 -->
    <div class="relative">
      <img :src="bannerImg" class="w-max" style="object-fit: cover;">
      <!-- tab栏 -->
      <!-- <tabList :tabList='tabList' /> -->
      <div class="tab_box flex col-center pl76 pr76 fs20" v-if="tabList.length>0">
        <div class="fw-500 flex col-center flex1" v-for="(item,i) in tabList" :key="i" @click="check(item.id,i)">
          <div class="tab_titel fw-600 flex" :class="{'color-f':i==active}">{{item.categoryName}}</div>
          <div class="line_box mr76 ml76" v-if="tabList.length-1!=i"></div>
        </div>
      </div>
      <!-- //tab栏 -->
    </div>
    <!-- //轮播图 -->

    <!-- 商品列表 -->
    <div class="content pt146 pl95 pr95 flex flex-wrap">
      <div class="goods_item w550 mr40 mb40" v-for="item in goodsList" :key="item.id">
        <img :src="item.pcPic" class="w-max h440">
        <div class="pt30 pl40 pr40 pb24 flex row-column col-center">
          <div class="fs18 fw-500">{{item.productName}}</div>
          <div class="fs18 mt16 mb24">{{item.productIntro}}</div>
          <div class="seeD" @click="gotoD(item.id)">查看详情</div>
        </div>
      </div>
    </div>
    <!-- //商品列表 -->
    <div class="fs18 text-center" style="cursor: pointer;" @click="moreBtn">加载更多<i class="el-icon-arrow-down fs18"
        style="color: #F7B500;"></i></div>
  </div>
</template>

<script>
import tabList from '@/components/tabList.vue'
import { getGoodsCategory, getCoopCase, getGoodsList } from '@/api/index'
import bannerImgJs from '@/mixin/bannerImg'
export default {
  data () {
    return {
      // tab列表
      tabList: [],
      //选中项
      active: 0,
      query: {
        //选中的tabId
        categoryId: '',
        pageNum: 1,
        pageSize: 6
      },
      //产品列表
      goodsList: [],
      //产品列表总数
      count: '',
      bannerType: 2
    };
  },
  mixins: [bannerImgJs],
  components: { tabList },

  created () {
    this.$store.commit('editRouterName', this.$route.name)
    //获取产品tab列表
    this.getGoodsCategory()
  },

  mounted () { },

  methods: {
    async getGoodsCategory () {
      const res = await getGoodsCategory()
      this.tabList = res.data
      this.query.categoryId = res.data[0].id
      this.getGoodsList(this.query)
    },
    //获取产品列表
    async getGoodsList (query) {
      const res = await getGoodsList(query)
      this.count = res.data.count
      this.goodsList = res.data.obj
    },
    //tab栏切换
    check (id, i) {
      this.query.categoryId = id
      this.active = i
      this.getGoodsList(this.query)
    },
    //加载更多
    moreBtn () {
      if (this.goodsList.length < this.count) {
        this.query.pageNum++
        this.getGoodsList(this.query)
      } else {
        this.$message.warning('暂无更多产品')
      }
    },
    gotoD (id) {
      this.$router.push(`/goodsD/${id}`)
    }
  }
}
</script>
<style lang='scss' scoped>
.content {
  .goods_item {
    background-color: #f2f2f2;
  }
  .goods_item:nth-child(3n+0) {
	  margin-right: 0 !important;
  }

  .seeD {
    width: 100px;
    height: 32px;
    color: #000000 !important;
    border: 1px solid #000000;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
  }

  .seeD:hover {
    background: #000;
    color: #fff !important;
  }
}
.tab_box {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  height: 100px;
  background: #f7b500;
  z-index: 888;

  .tab_titel {
    white-space: nowrap;
    cursor: pointer;
  }

  .line_box {
    width: 1px;
    height: 70px;
    border: 0.1px solid rgba(0, 0, 0, 0.1);
  }
}
</style>
